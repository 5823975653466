<!-- DEPRECATED -->
<template>
    <v-card outlined :id="randomID">
      <v-card-title>
      <v-row align="end">
        <v-col :cols="12" :md="$slots['title-append'] ? 8 : 12" style="text-align: center">
          <slot name="title">Chart</slot>
        </v-col>

        <v-col
          style="text-align: center"
          v-if="$slots['title-append']"
          :cols="12"
          :md="4"
          class="text-body-2 grey--text"
        >
          <slot name="title-append"/>
        </v-col>
      </v-row>
    </v-card-title>

      <v-divider />

      <v-card-text>
        <slot />

        <chartist
          type="Bar"
          :ratio="`ct-chart ${ratio}`"
          :data="chartData"
          :options="chartOptions"
          :event-handlers="eventHandlers"
        />
      </v-card-text>
    </v-card>
</template>

<script>
import * as d3 from 'd3'
import ChartistLegend from 'chartist-plugin-legend'
import chartistTooltipMixin from '@/utils/mixins/charts/chartistTooltip.mixin'
import chartLegendMixin from '@/utils/mixins/charts/chartLegend.mixin'
import styleMixin from '@/utils/mixins/styling/style.mixin'

export default {
  name: 'pibot-chartist-bar',
  mixins: [chartistTooltipMixin, chartLegendMixin, styleMixin],
  props: {
    data: {
      type: Array,
      default: () => null
    },
    useTooltip: {
      type: Boolean,
      default: true
    },
    'stack-data': {
      type: Array,
      default: () => null
    },
    labels: {
      type: Array,
      required: true
    },
    ratio: {
      type: String,
      default: 'ct-twelfth'
    },
    legend: {
      type: Array,
      default: () => null
    },
    width: {
      type: Number,
      default: 250
    },
    height: {
      type: Number,
      default: 250
    },
    colorScheme: {
      type: [String, Array],
      default: 'schemeCategory10'
    }
  },
  data: () => ({
    cssArray: []
  }),
  computed: {
    chartData () {
      return {
        labels: this.labels,
        series: this.data || this.stackData
      }
    },
    chartOptions () {
      const plugins = []
      if (this.useTooltip) {
        plugins.push(
          this.CustomTooltip({
            percentage: false,
            tooltipOffset: {
              x: 0,
              y: -30
            }
          })
        )
      }
      if (this.legend) {
        plugins.push(
          ChartistLegend({
            legendNames: this.legend
          })
        )
      }
      return {
        axisX: {
          showGrid: false,
          onlyInteger: true
        },
        axisY: {
          offset: 50,
          scaleMinSpace: 25,
          onlyInteger: true,
          labelInterpolationFnc: (value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        stackBars: !!this.stackData,
        chartPadding: { // 1rem of 2 rem?
          top: this.stackData ? 32 : 16,
          left: this.stackData ? 32 : 16,
          right: this.stackData ? 32 : 16
        },
        height: this.height,
        plugins: plugins
      }
    },
    eventHandlers () {
      return [
        {
          event: 'draw',
          fn: this.setColorScheme
        },
        {
          event: 'created',
          fn: this.handleLegendColors
        }
      ]
    },
    colors () {
      if (!Array.isArray(this.colorScheme)) return d3[this.colorScheme]
      return this.colorScheme
    }
  },
  methods: {
    setColorScheme (context) { // Set the 'stroke' for each bar and prepare legend CSS
      const { type, index, seriesIndex, element } = context

      if (type === 'bar') {
        const isStacked = !!this.stackData
        const itemIndex = isStacked ? seriesIndex : index
        const color = this.colors[itemIndex]

        // Create a CSS block and store it
        this.cssArray.push(this.createLegendCssLine(this.randomID, color, itemIndex))

        element.attr({ style: `stroke: ${color}` })
      }
    },
    handleLegendColors (eventName) { // Add a <style> block to <head> for styling chart legend items
      this.setLegendCSS(this.cssArray.join(' '))
    }
  },
  mounted () {
    if (!this.data && !this.stackData) throw Error('Either data of stack-data prop must be set')
  }
}

</script>

<style>
/*            Tooltip            */
@namespace ct "http://gionkunz.github.com/chartist-js/ct";

.ct-chart .ct-series.ct-series-a .ct-bar[ct|meta="Dry Gas"] {
  stroke: brown;
  stroke-width: 30px
}
.ct-chart .ct-series.ct-series-a .ct-bar[ct|meta="Crude Oil"] {
  stroke: black;
  stroke-width: 30px
}
.ct-chart .ct-series.ct-series-a .ct-bar[ct|meta="Water"] {
  stroke: blue;
  stroke-width: 30px
}
.ct-chart .ct-series.ct-series-a .ct-bar[ct|meta="Hydrogen"] {
  stroke: lightblue;
  stroke-width: 30px
}
.ct-chart .ct-series.ct-series-a .ct-bar[ct|meta="Decom."] {
  stroke: purple;
  stroke-width: 30px
}

.ct-chart .ct-series.ct-series-a .ct-bar {
  stroke: green;
  stroke-width: 30px
}
.ct-chart .ct-series.ct-series-b .ct-bar {
  stroke: orange;
  stroke-width: 30px
}
.ct-chart .ct-series.ct-series-c .ct-bar {
  stroke: red;
  stroke-width: 30px
}

.ct-label {
  pointer-events: none;
  font: 1em 'Roboto', sans-serif;
  color: #5c5c5c
}

.ct-chart {
  position: relative;
}

.ct-tooltip {
  position: absolute;
  display: inline-block;
  min-width: 3em;
  padding: 5px 5px;
  background: #383838;
  border-radius: 5%;
  text-anchor: middle;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  opacity: 0;
  font-family: 'Roboto, sans-serif';
  font-size: '20';
  transition: opacity .2s linear;
}

.ct-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: -8px;
}

.ct-tooltip.tooltip-show {
  opacity: 0.8;
}

/*            Legend            */
.ct-legend {
  position: relative;
  z-index: 10;
  list-style: none;
  text-align: center;
}
.ct-legend li {
  position: relative;
  padding-left: 23px;
  margin-right: 10px;
  margin-bottom: 3px;
  cursor: pointer;
  display: inline-block;
}
.ct-legend li:before {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  content: '';
  border: 3px solid transparent;
  border-radius: 2px;
}
.ct-legend li.inactive:before {
  background: transparent;
}
.ct-legend.ct-legend-inside {
  position: absolute;
  top: 0;
  right: 0;
}
.ct-legend.ct-legend-inside li{
  display: block;
  margin: 0;
}
/* .ct-legend .ct-series-0:before {
  background-color: green;
}
.ct-legend .ct-series-1:before {
  background-color: orange;
}
.ct-legend .ct-series-2:before {
  background-color:red;
} */

</style>
